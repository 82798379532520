<template>
  <v-dialog
    v-model="dialog"
    max-width="512"
    @click:outside="closeAction()"
  >
    <v-card>
      <v-card-title>
        <v-container class="ma-0 pa-0">
          <v-row>
            <v-col class="d-flex mt-n2">
              <div
                class="dialog-title d-inline-block align-self-start text-subtitle-1
              "
                style="width: 80%"
              >
                {{$t('agenda.downloadAgendas')}}
              </div>
              <div
                class="d-inline-block text-right align-self-start"
                style="width: 20%"
              >
                <v-icon
                  class="mt-n2 mr-n2"
                  @click="closeAction()"
                >
                  mdi-close
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text class="dialog-text">
        {{$t('agenda.downloadAgendasExcel')}}
        <br>
      </v-card-text>

      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-text>

          <v-select
            class="mb-4"
            v-model="selectedAgendaSavedSearches"
            :items="items"
            item-text="title"
            return-object
            :label="$t('agenda.selectAgendas')"
            :rules="selectedAgendaRules"
            multiple
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="toggle"
              >
                <v-list-item-action>
                  <v-icon :color="selectedAgendaSavedSearches.length > 0 ? 'teal darken-4' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{$t('generic.selectAll')}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="{ index }">
              <span
                v-if="(index === 0)"
                class="grey--text text-caption"
              >
                {{ selectedAgendaSavedSearches.length }} {{$t('generic.selected')}}
              </span>
            </template>
          </v-select>

          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-click="false"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                :label="$t('agenda.selectPeriod')"
                prepend-icon="mdi-calendar"
                readonly
                :rules="dateRangeTextRules"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              :max="max"
              :min="min"
              reactive
              @change="updateDates"
              elevation="4"
              :locale="userPreferences.locale"
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                {{$t('generic.cancel')}}
              </v-btn>
            </v-date-picker>
          </v-menu>
          <div class="text-caption mt-2 grey--text">
            *{{ $t('agenda.searchDownloadCalendar') }}
          </div>
        </v-card-text>
        <v-card-actions class="mt-5">
          <v-spacer />
          <v-btn
            class="white--text"
            color="teal lighten-1"
            @click="getAgendaExportToExcel"
          >
          {{$t('agenda.downloadAgendas')}}
            <v-icon
              right
            >
              mdi-download
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-form>

    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { hideDialog } from '@/helpers/dialogHelper';
import { mapGetters } from 'vuex';

export default {

  data() {
    return {
      dialog: true,
      valid: false,
      menu: false,
      dates: [
        new Date().toISOString(),
        // Always offer one month from now selected as a pre-filled option
        moment(new Date()).add(1, 'M').toISOString(),
      ],
      convertedDates: [],
      selectedAgendaSavedSearches: [],
      selectedAgendaRules: [
        (v) => v.length > 0 || this.$t('agenda.selectMinimum'),
      ],
      dateRangeTextRules: [
        // This regex validates this pattern: '16-01-2022 tot 02-03-2022'
        (v) => /(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})\s+\b(\w*tot\w*)\b\s+(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-([12]\d{3})/.test(v) || this.$t('agenda.selectDateRange'),
      ],
    };
  },

  props: {
    data: {
      type: Object,
      default() {
        return {

        };
      },
    },
  },

  computed: {
    ...mapGetters({
      agendaSavedSearches: 'agendaAllSavedSearches',
      userPreferences: 'userPreferences',
    }),

    items() {
      return this.agendaSavedSearches.data;
    },

    dateRangeText() {
      if (this.dates[0] > this.dates[1]) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dates = this.dates.reverse();
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.$set(this.convertedDates, 0, moment(this.dates[0]).format('DD-MM-YYYY'));
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.$set(this.convertedDates, 1, this.dates[1] ? moment(this.dates[1]).format('DD-MM-YYYY') : '');
      return this.convertedDates.join(` ${this.$t('generic.to')} `);
    },
    likesAllAgendas() {
      return this.selectedAgendaSavedSearches.length === this.agendaSavedSearches.data.length;
    },
    likesSomeAgendas() {
      return this.selectedAgendaSavedSearches.length > 0 && !this.likesAllAgendas;
    },
    icon() {
      if (this.likesAllAgendas) return 'mdi-close-box';
      if (this.likesSomeAgendas) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    min() {
      const start = new Date();
      // Last number in the row is meant for months
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 3);
      return start.toISOString();
    },
    max() {
      const end = new Date();
      // Last number in the row is meant for months
      end.setTime(end.getTime() + 3600 * 1000 * 24 * 30 * 6);
      return end.toISOString();
    },
  },

  mounted() {
    this.$set(this.convertedDates, 0, moment(this.dates[0]).format('DD-MM-YYYY'));
    this.$set(this.convertedDates, 1, moment(this.dates[1]).format('DD-MM-YYYY'));
  },

  methods: {
    hideDialog,

    closeAction() {
      this.hideDialog();
    },

    updateDates() {
      this.$refs.menu.save(this.dates);
    },

    async getAgendaExportToExcel() {
      if (this.$refs.form.validate()) {
        const data = {};
        data.savedSearches = this.selectedAgendaSavedSearches;
        data.startDate = new Date(this.dates[0]).toISOString();
        data.endDate = new Date(this.dates[1]).toISOString();
        const response = await this.$store.dispatch('agendaExportToExcel', data);
        const url = window.URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'agendas.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    },

    toggle() {
      this.$nextTick(() => {
        if (this.likesAllAgendas) {
          this.selectedAgendaSavedSearches = [];
        } else {
          this.selectedAgendaSavedSearches = this.agendaSavedSearches.data.slice();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .dialog-title {
    font-size: 16px !important;
  }
  .dialog-text {
    font-size: 13.4px !important;
  }
</style>
