<template>
  <div id="agenda">
    <agenda-saved-searches-form />
    <base-layout>
      <template v-slot:drawer-left>
        <agenda-sidebar v-if="agendaFiltersAreSet" />
      </template>

      <template v-slot:identity>
        <a
          href="/agenda"
          class="d-flex align-start"
        >
          <img
            style="cursor: pointer;"
            :src="logoSrc"
            alt="Polpo - Politieke monitoring"
            height="44"
          >
        </a>
      </template>

      <template v-slot:searchbar>
        <search-bar
          on-search-action="getAllAgendas"
          set-query-action="setAgendaQuery"
          search-query="agendaQuery"
          :query-length="200"
          :placeholder="$t('agenda.searchInAgendas')"
        />
      </template>

      <template v-slot:main-content>
        <div class="pl-1">
          <v-sheet
            tile
            class="d-flex pa-2"
          >
            <v-btn
              icon
              @click="$refs.calendar.prev()"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>

            <v-btn-toggle
              mandatory
              dense
              color="primary"
              v-model="calendarViewIndex"
              @change="setCalenderViewIndex"
            >
              <v-tooltip
                bottom
                open-delay="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-calendar-month</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('agenda.monthView') }}</span>
              </v-tooltip>

              <v-tooltip
                bottom
                open-delay="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-calendar-week</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('agenda.weekView') }}</span>
              </v-tooltip>

              <v-tooltip
                bottom
                open-delay="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-calendar-today</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('agenda.dayView') }}</span>
              </v-tooltip>
            </v-btn-toggle>

            <v-tooltip
              v-if="calendarViewType === 'day'"
              bottom
              open-delay="1000"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-2"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="setToday"
                >
                  <v-icon>mdi-calendar-check</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('agenda.today') }}</span>
            </v-tooltip>

            <v-spacer />
            <v-toolbar-title
              v-if="$refs.calendar"
              class="mt-1"
            >
              {{ $refs.calendar.title.toUpperCase() }}
            </v-toolbar-title>

            <v-spacer />

            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click.stop="showAgendaExportToExcelDialog"
            >
              {{ $t('agenda.downloadAgendasExcel') }}
            </v-btn>

            <v-btn
              icon
              @click="$refs.calendar.next()"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-sheet>
          <div ref="container"></div>
          <v-sheet class="calendar-container">
            <v-calendar
              ref="calendar"
              v-model="focusDate"
              color="primary"
              :locale="userPreferences.locale"
              first-time="00:00"
              locale-first-day-of-year="4"
              :weekdays="weekday"
              :type="calendarViewType"
              :events="events"
              :event-overlap-mode="mode"
              :event-overlap-threshold="30"
              :event-color="getEventColor"
              :show-week="true"
              :event-more="true"
              @moved="onCalenderMove"
              @change="onCalenderChange"
              @click:event="gotoAgenda"
              @mouseenter:event="showInfo"
              @mouseleave:event="hideInfo"
              @click:more="viewDay"
              @click:date="viewDay"
            />
          </v-sheet>
        </div>
      </template>

      <template v-slot:drawer-right>
        <!-- <agenda-saved-searches /> -->
        <saved-searches
          v-if="agendaFiltersAreSet"
          class="mt-n4"
          dashboard="agenda"
        />
      </template>
    </base-layout>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
// Vuex
import { mapGetters } from 'vuex';

import { showDialog } from '@/helpers/dialogHelper';
import { selectLogo } from '@/helpers/localeHelper';

// Import the logo images
import logoPolpoLightEnglish from '@/assets/logo-transparent-english.png';
import logoPolpoDark from '@/assets/polpo-logo-dark.png';
import logoPolpoLight from '@/assets/polpo-logo-light.png';

// @ is an alias to /src
import Config from '@/config';
import SearchBar from '@/components/SearchBar.vue';

import BaseLayout from '@/components/BaseLayout/BaseLayout.vue';
import SavedSearches from '@/components/SavedSearches/SavedSearches.vue';
// import AgendaSavedSearches from '../components/AgendaSavedSearches.vue';
import FeatureTeaserListManager from '@/components/FeatureTeaserListManager.vue';
import AgendaSavedSearchesForm from '@/modules/agendaDashboard/components/AgendaSavedSearchesForm.vue';
import AgendaExportToExcelDialog from '../components/AgendaExportToExcelDialog.vue';
import AgendaTooltip from '../components/AgendaTooltip.vue';
import AgendaSidebar from '../components/AgendaSidebar.vue';

export default {
  name: 'AgendaDashboard',

  components: {
    AgendaSavedSearchesForm,
    BaseLayout,
    AgendaSidebar,
    SearchBar,
    SavedSearches,
  },

  data: () => ({
    agendaFiltersAreSet: false,

    calendarViewTypes: ['month', 'week', 'day'],
    calendarViewType: 'month',
    calendarViewIndex: 0,
    calendarViewEnum: {
      month: 0,
      week: 1,
      day: 2,
    },

    mode: 'column',
    modes: ['stack', 'column'],
    weekday: [1, 2, 3, 4, 5],
    focusDate: '',
    events: [],
    eventsTable: [],
    show: false,
    positionX: null,
    positionY: null,
    element: null,
  }),

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      agendas: 'agendaAll',
      filters: 'userFilters',
      userPreferences: 'userPreferences',
      agendaFilters: 'agendaFilters',
      agendaQuery: 'agendaQuery',
      searchParameters: 'allSearchParameters',
    }),

    theme() {
      const mode = this.$vuetify.theme.dark ? 'dark' : 'light';
      return mode;
    },

    logoSrc() {
      const logoType = selectLogo(this.userPreferences.locale, this.$vuetify.theme.dark);
      switch (logoType) {
        case 'light-english':
          return logoPolpoLightEnglish;
        case 'dark':
          return logoPolpoDark;
        case 'light':
        default:
          return logoPolpoLight;
      }
    },
  },

  methods: {
    showAgendaExportToExcelDialog() {
      const data = {
        // agendas: this.agendas,
      };
      showDialog(AgendaExportToExcelDialog, data);
    },

    logMouse(e) {
      this.positionX = e.clientX;
      this.positionY = e.clientY;
    },

    gotToRoute(name) {
      this.$router.push({ name, query: { query: this.$route.query.query } });
    },

    setCalendarView(viewType) {
      this.calendarViewType = viewType;
    },
    setToday() {
      this.focusDate = '';
    },
    showTeaserDialog() {
      showDialog(FeatureTeaserListManager);
    },
    gotoAgenda({ event }) {
      const { id } = event;
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$router.push({ path: `/agenda/${id}/?query=${this.agendaQuery}` });
      } else {
        window.open(`/agenda/${id}/?query=${this.agendaQuery}`, '_blank');
      }
    },

    showInfo({ event }) {
      this.show = true;
      const ComponentClass = Vue.extend(AgendaTooltip);
      this.element = new ComponentClass({
        propsData: {
          show: this.show,
          posx: this.positionX,
          posy: this.positionY,
        },
      });
      this.element.$slots.default = [event.name];
      this.element.$mount(); // pass nothing
      this.$refs.container.appendChild(this.element.$el);
    },

    hideInfo() {
      this.show = false;
      this.$refs.container.removeChild(this.element.$el);
      this.element.$destroy();
    },

    viewDay({ date }) {
      this.focusDate = date;
      this.setCalenderViewIndex(this.calendarViewEnum.day);
    },
    setCalenderViewIndex(index) {
      this.calendarViewIndex = index;
      this.calendarViewType = this.calendarViewTypes[index];
    },

    createStartDate(startDate) {
      return `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).length === 1
        ? `0${startDate.getMonth() + 1}` : `${startDate.getMonth() + 1}`}-${String(startDate.getDate()).length === 1
        ? `0${startDate.getDate()}` : `${startDate.getDate()}`}T00%3A00%3A00.000`;
    },

    createEndDate(endDate) {
      return `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).length === 1
        ? `0${endDate.getMonth() + 1}` : `${endDate.getMonth() + 1}`}-${String(endDate.getDate()).length === 1
        ? `0${endDate.getDate()}` : `${endDate.getDate()}`}T23%3A59%3A59.000`;
    },

    onCalenderChange(obj) {
      // TODO: Fix correctedStartDate hack when troubles with datetime daylight
      // saving fix in backend and collector has been solved
      const correctedStartDate = new Date(obj.start.date).getTime() - (2 * 60 * 60 * 1000);
      const startDate = new Date(correctedStartDate);
      const endDate = new Date(obj.end.date);
      const min = this.createStartDate(startDate);
      const max = this.createEndDate(endDate);

      if (this.agendaQuery) {
        this.$store.dispatch('setAgendaStartDate', min);
        this.$store.dispatch('setAgendaEndDate', max);
        this.$store.dispatch('getAllAgendas');
      }
    },

    onCalenderMove() {
      this.getEvents();
    },

    setInitialDateRange() {
      const startDate = new Date(this.$refs.calendar.lastStart.date);
      const endDate = new Date(this.$refs.calendar.lastEnd.date);

      const min = this.createStartDate(startDate);
      const max = this.createEndDate(endDate);

      this.$store.dispatch('setAgendaStartDate', min);
      this.$store.dispatch('setAgendaEndDate', max);
    },

    flattenNestedArray(treeObject) {
      let ids = [];

      treeObject.forEach((treeItem) => {
        if (treeItem.children && treeItem.children.length > 0) {
          const childIds = this.flattenNestedArray(treeItem.children);
          ids = [...ids, ...childIds];
        } else {
          ids.push(treeItem.id);
        }
      });
      return ids;
    },

    getEvents() {
      const events = [];

      this.agendas.forEach((agenda) => {
        let allDay = false;
        let fixedStartTime = new Date(agenda.startTime);
        let fixedEndTime = agenda.endTime;

        if (agenda.origin === 'api' && !agenda.startTime) {
          // Deadlines are set to startTime because V-calendar events requires
          // a startTime but no endTime.
          // TODO KNOWN BUG: Deadlines after ~23:35 might be rendered (partially) below the
          // visible screen in day and week views.
          // TODO KNOWN BUG: Deadlines with startTime 00:00 (and no endTime) are not rendered at all.
          fixedStartTime = fixedEndTime;
          fixedEndTime = undefined;
        }

        if (agenda.origin === 'api' && (!agenda.startTime || agenda.startTime === agenda.endTime)) {
          fixedStartTime = new Date(new Date(agenda.endTime).getTime() - (1 * 60 * 60 * 1000));
        }

        if (!agenda.startTime && !agenda.endTime) {
          allDay = true;
          fixedStartTime = new Date(agenda.date);
          fixedEndTime = undefined;
        }

        events.push({
          id: agenda.id,
          name: `${agenda.title} - ${agenda.type} (${agenda.location} ${agenda.location === 'Plenaire zaal' ? '' : `, ${agenda.confidentiality}`})`,
          start: new Date(fixedStartTime).getTime(),
          end: fixedEndTime ? new Date(fixedEndTime).getTime() : undefined,
          color: Config.agenda.colors[agenda.confidentiality],
          kind: Config.agenda.colors[agenda.type === 'Plenair debat' ? 'plenair' : 'commission'],
          timed: !allDay,
          origin: agenda.origin,
        });
      });

      this.eventsTable = events.reduce((acc, date) => {
        // create a composed key: 'year-week'
        const yearWeek = `${moment(date.start).month()}-${moment(date.start).date()}`;

        // add this key as a property to the result object
        if (!acc[yearWeek]) {
          acc[yearWeek] = [];
        }

        // push the current date that belongs to the year-week calculated before
        acc[yearWeek].push(date);

        return acc;
      }, {});

      // Get all agenda filters
      const allAgendaFilters = [...this.filters.agenda];

      // Flatten all agenda filters
      const flatten = this.flattenNestedArray(allAgendaFilters);

      // Walk through the events per day and see whether there are more than
      // 10 items depending on all or none filters have been selected
      // Expose an info message when there are too many items found
      Object.values(this.eventsTable).forEach((value) => {
        if ((value.length > 10
          && (this.agendaFilters.length === flatten.length
          || this.agendaFilters.length === flatten.length - 1
          || this.agendaFilters.length === 0))
          || value.length > 10) {
          this.$store.dispatch('setMessage', {
            message: this.$t('agenda.largeMeetingsWarning'),
            type: 'info',
          });
        }
      });

      this.events = events;
    },

    getEventColor(event) {
      return event.kind;
    },

    parseQueryParams(queryParams) {
      const { query } = queryParams;
      if (query) {
        this.$store.dispatch('setAgendaQuery', query);
        this.$store.dispatch('getAllAgendas');
      }
    },
  },

  watch: {
    agendas() {
      this.getEvents();
    },
  },

  async mounted() {
    this.$gtag.pageview({
      page_path: '/agenda',
    });
    this.setInitialDateRange();
    await this.$store.dispatch('getPreferences');
    this.$vuetify.theme.dark = this.$store.getters.userPreferences.uiDarkMode;
    await this.$store.dispatch('getAgendaFilterTypesForUser').then(() => {
      this.agendaFiltersAreSet = true;
      this.$store.dispatch('setAgendaFilters', []);
      this.$store.dispatch('agendaSetRecentSearches', []);
      this.$store.dispatch('agendaSetCurrentSearch', {});
    });

    await this.$store.dispatch('agendaGetAllSavedSearches');

    await this.$store.dispatch('clearMessages');
    this.parseQueryParams(this.$route.query);

    document.addEventListener('mousemove', this.logMouse);
  },

  destroyed() {
    document.removeEventListener('mousemove', this.logMouse);
  },

};
</script>

<style scoped lang="scss">

.calendar-container {
  height: calc(100vh - 116px);
}

::v-deep {
  .calendar-container {
    .v-calendar-weekly__day.v-present .v-btn.v-btn--fab {
      background-color: #F44336 !important;
      border-color: #F44336 !important;
    }

     .v-calendar-weekly__day-label button.v-btn--fab.v-size--small {
      height: 32px;
      width: 32px;
      margin-bottom: 5px;
    }
  }
}
</style>
